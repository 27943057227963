<template>
    <svg width="67" height="91" viewBox="0 0 67 91" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M43.3091 0.681482L2.6144 11.2892C1.17051 11.6656 0.305112 13.1412 0.681486 14.5851L19.9188 88.3856C20.2952 89.8295 21.7708 90.6949 23.2147 90.3185L63.9094 79.7108C65.3533 79.3344 66.2187 77.8588 65.8423 76.4149L46.6049 2.61439C46.2286 1.1705 44.7529 0.305108 43.3091 0.681482Z" fill="#B2B2B2" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.9994 9.15256C20.0911 9.50709 19.8771 9.86773 19.5287 9.95942C19.1742 10.0511 18.8135 9.83717 18.7218 9.48875C18.6302 9.13422 18.8441 8.77358 19.1925 8.68189C19.547 8.5902 19.9077 8.80414 19.9994 9.15256Z" fill="#F9D849" stroke="#F9F9F9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.3797 6.97037C28.4347 7.19042 28.3063 7.41659 28.0802 7.47771C27.8601 7.53273 27.634 7.40436 27.5728 7.1782C27.5178 6.95815 27.6462 6.73198 27.8723 6.67086C28.0924 6.61584 28.3186 6.74421 28.3797 6.97037Z" fill="#F9D849" stroke="#F9F9F9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.0773 7.31922L21.1975 8.59123C21.093 8.61848 21.0303 8.72531 21.0576 8.82984L21.0776 8.90674C21.1049 9.01127 21.2117 9.07392 21.3162 9.04668L26.196 7.77467C26.3006 7.74742 26.3632 7.64059 26.336 7.53605L26.3159 7.45916C26.2887 7.35463 26.1819 7.29197 26.0773 7.31922Z" fill="#F9D849" stroke="#F9F9F9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.3848 3.37007L64.4622 76.562C64.6822 77.4178 64.1749 78.2919 63.3191 78.5119L23.1473 88.9827C22.2916 89.2028 21.4175 88.6954 21.1974 87.8397L2.12008 14.6478C1.90003 13.792 2.40737 12.9179 3.26313 12.6979L11.509 10.5462C12.053 10.4056 12.6276 10.6135 12.9454 11.0719L13.5384 11.9216C13.8623 12.38 14.4369 12.5939 14.9748 12.4472L33.0802 7.72834C33.6243 7.58775 34.0216 7.12319 34.0766 6.56695L34.1805 5.53392C34.2355 4.97768 34.6328 4.51312 35.1769 4.37253L43.4227 2.22091C44.2785 2.00086 45.1526 2.5082 45.3726 3.36396L45.3848 3.37007Z" fill="#F9D849"/>
<path d="M26.4184 57.2728C26.5101 57.3246 26.6052 57.3721 26.7042 57.4155C28.1658 58.057 29.8436 57.5917 31.3885 57.1907C31.508 57.1597 31.5572 57.0167 31.4768 56.9229C30.8571 56.2006 30.2136 56.0171 28.9087 56.1137C28.7246 56.1273 28.5383 56.1231 28.3576 56.0853C27.4742 55.9003 27.01 55.6241 26.4887 55.16C26.2459 54.9437 26.0776 54.659 25.9534 54.3585C25.5508 53.3849 25.1027 52.9768 24.2505 52.7037C24.1331 52.666 24.0236 52.774 24.0545 52.8933C24.484 54.5476 24.9295 56.4335 26.4184 57.2728Z" fill="#B2B2B2"/>
<path d="M39.7309 37.4944C39.6391 37.4427 39.5441 37.3952 39.4451 37.3517C37.9835 36.7103 36.3057 37.1755 34.7608 37.5766C34.6413 37.6076 34.592 37.7506 34.6724 37.8443C35.2922 38.5667 35.9357 38.7501 37.2405 38.6535C37.4247 38.6399 37.611 38.6441 37.7917 38.682C38.675 38.867 39.1392 39.1431 39.6606 39.6073C39.9034 39.8235 40.0717 40.1083 40.1959 40.4087C40.5984 41.3823 41.0466 41.7905 41.8988 42.0636C42.0162 42.1012 42.1257 41.9933 42.0947 41.874C41.6653 40.2197 41.2198 38.3337 39.7309 37.4944Z" fill="#B2B2B2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.4759 50.5603C32.9825 49.9096 34.487 47.3502 33.8363 44.8436C33.1856 42.337 30.6261 40.8325 28.1195 41.4832C25.6129 42.1339 24.1085 44.6934 24.7592 47.2C25.4099 49.7066 27.9694 51.211 30.4759 50.5603ZM31.0068 52.6051C34.6426 51.6612 36.8249 47.9486 35.8811 44.3128C34.9372 40.6769 31.2246 38.4946 27.5887 39.4384C23.9528 40.3823 21.7705 44.0949 22.7144 47.7308C23.6583 51.3667 27.3709 53.549 31.0068 52.6051Z" fill="#B2B2B2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.0297 53.2841C40.5363 52.6333 42.0408 50.0739 41.3901 47.5673C40.7394 45.0607 38.1799 43.5562 35.6733 44.2069C33.1667 44.8576 31.6623 47.4171 32.313 49.9237C32.9637 52.4303 35.5232 53.9348 38.0297 53.2841ZM38.5606 55.3288C42.1964 54.385 44.3787 50.6723 43.4349 47.0365C42.491 43.4006 38.7784 41.2183 35.1425 42.1621C31.5066 43.106 29.3243 46.8186 30.2682 50.4545C31.2121 54.0904 34.9247 56.2727 38.5606 55.3288Z" fill="#B2B2B2"/>
<path d="M28.603 44.04L30.3559 50.7921L30.9922 46.3026L29.9405 42.2513L29.6029 42.339C28.857 42.5326 28.4094 43.2942 28.603 44.04Z" fill="#B2B2B2"/>
<path d="M37.59 50.8961L35.8372 44.1439L35.2009 48.6334L36.2526 52.6847L36.5902 52.5971C37.336 52.4034 37.7837 51.6419 37.59 50.8961Z" fill="#B2B2B2"/>
</svg>
</template>