<script setup lang="ts">
const depositType = ref("");
const depositAmount = ref(0);
const currency = ref("");
const termOfDeposit = ref(0);
import AMRA_BANK_LOGO from "@/assets/img/amra-bank.png";

const route = useRoute();
const head = computed(() => {
  return {
    title: "Амрабанк",
    meta: [
      {
        hid: "title",
        property: "title",
        content: `Амрабанк`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Амра-банк это дебетовые карты, обмен валюты, обслуживание физических лиц, кредитование бизнеса, аренда сейфовых ячеек, дистанционное обслуживание юридических ли...",
      },
      {
        property: "og:title",
        name: "og:title",
        content: "Амрабанк",
      },
      {
        property: "og:description",
        name: "og:description",
        content:
          "Амра-банк это дебетовые карты, обмен валюты, обслуживание физических лиц, кредитование бизнеса, аренда сейфовых ячеек, дистанционное обслуживание юридических ли...",
      },
      {
        property: "og:url",
        name: "og:url",
        content: `https://amra-bank.com/${route.fullPath}`,
      },
      {
        property: "url",
        name: "url",
        content: `https://amra-bank.com/${route.fullPath}`,
      },
      {
        property: "og:type",
        name: "og:type",
        content: "website",
      },
      {
        property: "og:site_name",
        name: "og:site_name",
        content: "Амрабанк",
      },
      {
        property: "image",
        name: "image",
        content: `${AMRA_BANK_LOGO}`,
      },
      {
        property: "og:image",
        name: "og:image",
        content: `${AMRA_BANK_LOGO}`,
      },
    ],
  };
});

useHead(head);
</script>
<template>
  <div>
    <NuxtLayout name="default">
      <ASlider></ASlider>
      <ClientOnly>
        <AInformation class="mt-4 md:mt-0 container"></AInformation>
        <AFinance class="mt-3 lg:mt-14"></AFinance>
        <ACalculator class="mt-8 lg:mt-14 container" v-model:deposit-type="depositType"
          v-model:deposit-amount="depositAmount" v-model:currency="currency" v-model:term-of-deposit="termOfDeposit">
        </ACalculator>
      </ClientOnly>
    </NuxtLayout>
  </div>
</template>
