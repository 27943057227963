<template>
    <section class="lg:container">
        <div class="grid lg:grid-cols-11 lg:gap-10 items-center">
            <div class="lg:col-start-1 lg:col-end-8">
                <AVerification></AVerification>
            </div>
            <div class="container lg:col-start-8 lg:col-end-12 place-content-center">
                <AOnline></AOnline>
            </div>
        </div>
    </section>
</template>