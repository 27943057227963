<template>
  <div class="bg-[#F9F9F9] lg:bg-[#ffffff]">
    <div
      class="relative container lg:border-[1px] border-[#E1E1E1] pt-5 pb-[60px] lg:pt-[30px] lg:pb-11 lg:pl-9 lg:rounded-2xl lg:flex lg:justify-between lg:px-0 px-4 lg:h-72">
      <div class="grid gap-2 relative">
        <div>
          <p class="text-xl md:text-3xl">{{ t('Проверьте статус платежа') }}</p>
          <p class="text-[#979797] text-sm lg:text-base pt-[10px] lg:pt-1 font-sans">{{ t('check_status_subtitle') }}</p>
        </div>
        <div class="!relative w-full z-30 pt-4 lg:pt-0">
          <input
            class="rounded-xl !bg-[#FFFFFF] md:rounded-3xl w-full max-w-110 h-[50px] pl-5 py-[14px] border focus:outline-none"
            height="large" maxlength="20" v-mask="'####################'" type="text" v-model="check"
            @blur="validator.check.$commit" :isValid="!validator.check.$error"
            :placeholder="t('check_number_placeholder')"
            :class="validator.check.$error ? 'border-red' : 'border-[#E3E3E3]'">
          <AButton width="medium" class="!absolute !top-0 right-0 lg:block hidden" type="yellow"
            @click="sendDeposits()">{{ t('check_button_label') }}</AButton>
          <div class="!relative">
            <p class="mt-3 text-[#919191] border-b w-43 cursor-pointer hover:text-[#636363]" @click="toggleHelp">{{
              t('where_find_check_number') }}</p>
            <div v-if="showHelp"
              class="absolute w-[250px] h-[230px] rounded-[20px] bg-[#ffffff] border-[#E3E3E3] border top-10 px-3 pt-9">
              <div class="absolute right-[10px] top-[10px]">
                <button @click="closeHelp" class="flex items-center justify-center w-5 h-5">
                  <span class="block relative w-6 h-6">
                    <span
                      class="absolute inset-0 bg-black transform rotate-45 group-hover:rotate-[135deg] transition-transform duration-200 ease-in-out"
                      style="width: 2px; height: 100%; left: 50%; top: 0;"></span>
                    <span
                      class="absolute inset-0 bg-black transform -rotate-45 group-hover:-rotate-[135deg] transition-transform duration-200 ease-in-out"
                      style="width: 2px; height: 100%; left: 50%; top: 0;"></span>
                  </span>
                </button>
              </div>
              <a-icon-chek class="w-full h-full"></a-icon-chek>
            </div>
          </div>
        </div>
        <AButton width="mobile" class="block lg:hidden" type="yellow">{{ t('check_button_label') }}</AButton>
        <div class="relative flex flex-row space-x-1 items-center" v-if="!!payment">
          <template v-if="payment.payState === 'Платеж завершен'">
            <a-icon-confiramation></a-icon-confiramation>
            <p class="font-sans text-base md:text-xl-[#016B18]">Платёж зачислен</p>
          </template>
          <template v-else>
            <a-icon-refusal></a-icon-refusal>
            <p class="font-sans text-base md:text-xl text-[#AA0303]">Некорректный номер чека</p>
            <p class="absolute z-40 -bottom-6 whitespace-nowrap left-0 text-sm md:text-base font-sans text-[#6F6F6F]">Вы
              можете обратиться в службу поддержки клиентов <a href="tel:+78402233335" class="underline">+7 (840)
                223-33-35</a></p>
          </template>
        </div>
      </div>
      <a-icon-verification class="hidden z-10 lg:block absolute bottom-0 right-0 rounded-2xl"></a-icon-verification>
    </div>
  </div>
</template>

<script setup lang="ts">
import { required, minLength } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core";
import { ref, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import aIconConfirmation from '@/components/a-icon/a-icon-confirmation.vue';
import aIconRefusal from '@/components/a-icon/a-icon-refusal.vue';
import aIconExpectation from '@/components/a-icon/a-icon-expectation.vue';

const { t } = useI18n({ useScope: 'local' });

const check = ref('');
const showHelp = ref(false);
const payment = ref(null);
const isSubmit = ref(false);
const isSubmited = ref(false);

const rules = reactive({
  check: {
    required,
    minLength: minLength(20),
  },
});

const validator = useVuelidate(rules, { check }, { $rewardEarly: true });

async function sendDeposits() {
  isSubmit.value = true;
  try {
    const isValid = await validator.value.$validate();
    if (!isValid) {
      alert("Проверьте правильность введенных данных");
      isSubmit.value = false;
      return;
    }
    payment.value = await $fetch(`/api/v1/transaction-check?check=${check.value}`);
    isSubmit.value = false;
    isSubmited.value = true;
  } catch (e) {
    isSubmit.value = false;
    alert("Возникла ошибка. Попробуйте позже");
    console.error(e);
  }
}

watch(check, () => {
  payment.value = null;
});

const toggleHelp = () => {
  showHelp.value = !showHelp.value;
};

const closeHelp = () => {
  showHelp.value = false;
};
</script>

<i18n lang="json">{
  "ru": {
    "Проверьте статус платежа": "Проверьте статус платежа",
    "check_status_subtitle": "проведенного в терминалах Амра-Банка или его дилеров",
    "check_number_placeholder": "Номер чека",
    "check_button_label": "Проверить",
    "where_find_check_number": "Где найти номер чека?",
    "payment_credited": "Платёж зачислен",
    "payment_pending": "Платёж ожидает зачисления",
    "payment_declined": "Платёж отклонен",
    "incorrect_check_number": "Не корректный номер чека"
  },
  "en": {
    "Проверьте статус платежа": "Check Payment Status",
    "check_status_subtitle": "processed in Amra-Bank terminals or its dealers",
    "check_number_placeholder": "Check Number",
    "check_button_label": "Check",
    "where_find_check_number": "Where to find the check number?",
    "payment_credited": "Payment Credited",
    "payment_pending": "Payment Pending",
    "payment_declined": "Payment Declined",
    "incorrect_check_number": "Incorrect Check Number"
  },
  "es": {
    "Проверьте статус платежа": "Verificar el estado del pago",
    "check_status_subtitle": "procesado en los terminales de Amra-Bank o sus distribuidores",
    "check_number_placeholder": "Número de cheque",
    "check_button_label": "Verificar",
    "where_find_check_number": "¿Dónde encontrar el número de cheque?",
    "payment_credited": "Pago Acreditado",
    "payment_pending": "Pago Pendiente",
    "payment_declined": "Pago Rechazado",
    "incorrect_check_number": "Número de cheque Incorrecto"
  },
  "ar": {
    "Проверьте статус платежа": "تحقق من حالة الدفع",
    "check_status_subtitle": "المعالجة في أجهزة الصراف الآلي الخاصة بـ Amra-Bank أو وكلائها",
    "check_number_placeholder": "رقم الشيك",
    "check_button_label": "تحقق",
    "where_find_check_number": "أين يمكن العثور على رقم الشيك؟",
    "payment_credited": "تم استلام الدفع",
    "payment_pending": "الدفع في الانتظار",
    "payment_declined": "تم رفض الدفع",
    "incorrect_check_number": "رقم الشيك غير صحيح"
  },
  "tr": {
    "Проверьте статус платежа": "Ödeme Durumunu Kontrol Et",
    "check_status_subtitle": "Amra-Bank veya bayilerinin terminallerinde işlenmiş",
    "check_number_placeholder": "Çek Numarası",
    "check_button_label": "Kontrol Et",
    "where_find_check_number": "Çek numarasını nerede bulabilirim?",
    "payment_credited": "Ödeme Yapıldı",
    "payment_pending": "Ödeme Beklemede",
    "payment_declined": "Ödeme Reddedildi",
    "incorrect_check_number": "Yanlış Çek Numarası"
  }
}</i18n>