<template>
    <svg width="81" height="51" viewBox="0 0 81 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1595_422)">
<path d="M1.79062 7.66913L1.68741 44.9355C1.67844 48.1722 4.2736 50.8033 7.48386 50.8123L75.0697 51.0026C78.2799 51.0117 80.8896 48.3952 80.8986 45.1585L81.0018 7.89215C81.0108 4.65551 78.4156 2.02438 75.2053 2.01534L7.61954 1.82505C4.40929 1.81602 1.79959 4.4325 1.79062 7.66913Z" fill="#BCBCBC"/>
<path d="M79.5628 7.86227L79.4723 42.0644C79.4563 46.1539 76.1721 49.449 72.1213 49.4383L7.57462 49.2558C6.49405 49.2558 5.46671 49.0143 4.54584 48.585C2.01209 47.4258 0.260826 44.839 0.271472 41.8498L0.298087 32.2433L0.361963 7.6476C0.367286 3.55816 3.66222 0.257618 7.71302 0.268352L33.6094 0.343486L52.8627 0.397153L72.2596 0.450821C76.3104 0.461554 79.5788 3.78356 79.5681 7.86227H79.5628Z" fill="#F9D849"/>
<path d="M6.84428 7.74409L6.82756 13.7816C6.82421 14.9909 7.79383 15.974 8.99327 15.9774L18.5374 16.0042C19.7368 16.0076 20.7118 15.03 20.7152 13.8207L20.7319 7.78319C20.7353 6.5739 19.7656 5.59084 18.5662 5.58746L9.02212 5.56059C7.82268 5.55721 6.84763 6.5348 6.84428 7.74409Z" fill="#B2B2B2" stroke="#B2B2B2" stroke-width="0.532299" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.83472 10.6315L20.7224 10.8837" stroke="#B2B2B2" stroke-width="0.532299" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.7919 15.9929L13.8291 5.57603" stroke="#B2B2B2" stroke-width="0.532299" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M74.7561 42.7245L74.7242 44.4795L46.3792 44.399L46.3899 42.6387L74.7561 42.7245Z" fill="#B2B2B2"/>
<path d="M74.7987 39.7406L74.7774 41.5009L46.3952 41.415V39.6655L74.7987 39.7406Z" fill="#B2B2B2"/>
<path d="M74.8519 36.7621L74.82 38.5224L46.4005 38.4419L46.4059 36.6816L74.8519 36.7621Z" fill="#B2B2B2"/>
<path d="M74.8945 33.7836L74.8679 35.5385L46.8211 35.458V33.703L74.8945 33.7836Z" fill="#B2B2B2"/>
<path d="M74.9478 30.5689L74.9211 32.3238L46.8317 32.2433L46.837 30.483L74.9478 30.5689Z" fill="#B2B2B2"/>
<path d="M54.0337 14.458L54.0071 16.2129L25.9177 16.1324L25.923 14.3721L54.0337 14.458Z" fill="#B2B2B2"/>
<path d="M0.376278 20.0688L0.368652 22.8219L79.5213 23.0448L79.5289 20.2917L0.376278 20.0688Z" fill="#B2B2B2"/>
<path d="M79.5628 7.86227L79.4723 42.0644C79.4563 46.1539 76.172 49.449 72.1213 49.4383L7.57462 49.2558C6.49405 49.2558 5.46671 49.0143 4.54584 48.585C2.01209 47.4258 0.260826 44.839 0.271472 41.8498L0.298087 32.2433L0.361963 7.6476C0.367286 3.55816 3.66222 0.257618 7.71302 0.268352L33.6094 0.343486L52.8627 0.397153L72.2596 0.450821C76.3104 0.461554 79.5788 3.78356 79.5681 7.86227H79.5628Z" stroke="#BCBCBC" stroke-width="0.532299" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1595_422">
<rect width="81" height="51" fill="white"/>
</clipPath>
</defs>
</svg>
</template>