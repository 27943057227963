<template>
    <svg width="88" height="85" viewBox="0 0 88 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_1595_390" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 37L12.2962 56.1249C22.3657 71.7863 43.2246 76.3196 58.8861 66.2501C74.5475 56.1807 79.0807 35.3217 69.0113 19.6603L56.715 0.535401L43.5254 9.01558L55.8218 28.1407C61.2078 36.5178 58.7831 47.6749 50.406 53.0609C42.029 58.4468 30.8719 56.0221 25.4859 47.645L13.1895 28.5199L0 37Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 37L12.2962 56.1249C22.3657 71.7863 43.2246 76.3196 58.8861 66.2501C74.5475 56.1807 79.0807 35.3217 69.0113 19.6603L56.715 0.535401L43.5254 9.01558L55.8218 28.1407C61.2078 36.5178 58.7831 47.6749 50.406 53.0609C42.029 58.4468 30.8719 56.0221 25.4859 47.645L13.1895 28.5199L0 37Z" fill="#D9D9D9"/>
<path d="M0 37L-0.540809 36.1589L-1.38195 36.6997L-0.841145 37.5408L0 37ZM12.2962 56.1249L13.1374 55.5841L12.2962 56.1249ZM69.0113 19.6603L69.8524 19.1195L69.0113 19.6603ZM56.715 0.535401L57.5562 -0.00540846L57.0154 -0.846554L56.1742 -0.305744L56.715 0.535401ZM43.5254 9.01558L42.9846 8.17443L42.1435 8.71524L42.6843 9.55638L43.5254 9.01558ZM13.1895 28.5199L14.0306 27.9791L13.4898 27.1379L12.6487 27.6787L13.1895 28.5199ZM-0.841145 37.5408L11.4551 56.6657L13.1374 55.5841L0.841145 36.4592L-0.841145 37.5408ZM11.4551 56.6657C21.8232 72.7917 43.3009 77.4594 59.4269 67.0913L58.3453 65.409C43.1484 75.1797 22.9081 70.781 13.1374 55.5841L11.4551 56.6657ZM59.4269 67.0913C75.5529 56.7232 80.2205 35.2454 69.8524 19.1195L68.1701 20.2011C77.9409 35.398 73.5421 55.6382 58.3453 65.409L59.4269 67.0913ZM69.8524 19.1195L57.5562 -0.00540846L55.8739 1.07621L68.1701 20.2011L69.8524 19.1195ZM56.1742 -0.305744L42.9846 8.17443L44.0663 9.85672L57.2559 1.37655L56.1742 -0.305744ZM56.663 27.5999L44.3666 8.47477L42.6843 9.55638L54.9807 28.6815L56.663 27.5999ZM50.9468 53.902C59.7884 48.2174 62.3476 36.4415 56.663 27.5999L54.9807 28.6815C60.068 36.594 57.7777 47.1324 49.8652 52.2197L50.9468 53.902ZM24.6447 48.1859C30.3294 57.0275 42.1052 59.5867 50.9468 53.902L49.8652 52.2197C41.9527 57.307 31.4143 55.0167 26.327 47.1042L24.6447 48.1859ZM12.3483 29.0607L24.6447 48.1859L26.327 47.1042L14.0306 27.9791L12.3483 29.0607ZM12.6487 27.6787L-0.540809 36.1589L0.540809 37.8411L13.7303 29.361L12.6487 27.6787Z" fill="#B2B2B2" mask="url(#path-1-inside-1_1595_390)"/>
<rect x="45.6926" y="9.48636" width="12.5444" height="14.1124" transform="rotate(-32.7387 45.6926 9.48636)" fill="#FFD600"/>
<rect x="2.16701" y="37.471" width="12.5444" height="14.1124" transform="rotate(-32.7387 2.16701 37.471)" fill="#FFED00"/>
</svg>
</template>