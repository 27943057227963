<template><svg width="240" height="279" viewBox="0 0 240 279" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M166.304 11.5562C189.74 9.61101 215.244 -7.64044 235.673 4.00891C256.821 16.0683 252.502 49.578 267.121 69.0456C280.196 86.4569 306.101 91.817 316.129 111.145C326.289 130.729 328.802 155.059 322.754 176.277C316.784 197.217 294.698 208.847 283.036 227.234C271.886 244.811 271.91 269.798 255.396 282.469C238.91 295.117 215.444 291.15 194.987 294.788C175.096 298.327 155.851 305.398 135.715 303.757C114.485 302.026 92.3801 297.828 75.3473 285.036C58.356 272.275 49.5071 251.7 40.6679 232.375C32.2851 214.048 31 193.899 24.8788 174.697C17.4026 151.246 -2.90155 130.303 0.349573 105.904C3.52984 82.0367 23.8516 63.7638 41.7991 47.7147C58.9716 32.3585 79.7563 21.5384 101.878 15.1137C122.761 9.04895 144.633 13.3548 166.304 11.5562Z" fill="#FFC700"/>
    <path d="M53.7597 95.5693C77.3178 95.5693 96.4154 76.5901 96.4154 53.1781C96.4154 29.7661 77.3178 10.7869 53.7597 10.7869C30.2016 10.7869 11.104 29.7661 11.104 53.1781C11.104 76.5901 30.2016 95.5693 53.7597 95.5693Z" stroke="#B2B2B2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.7597 88.3238C73.2912 88.3238 89.1246 72.5885 89.1246 53.1781C89.1246 33.7677 73.2912 18.0324 53.7597 18.0324C34.2282 18.0324 18.3947 33.7677 18.3947 53.1781C18.3947 72.5885 34.2282 88.3238 53.7597 88.3238Z" stroke="#B2B2B2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M59.3155 63.0691L53.7597 53.1781L32.2252 41.8934" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M130.735 106.764C130.735 106.764 115.437 109.039 114.503 114.121C105.224 164.351 124.469 174.975 124.469 174.975C124.469 174.975 118.485 172.427 118.204 177.236C117.916 182.046 121.625 187.428 122.475 187.707C123.331 187.993 132.16 201.011 134.436 195.064C136.712 189.117 136.143 120.906 137.281 114.965C138.419 109.018 133.291 106.191 130.728 106.757L130.735 106.764Z" fill="#F9D849" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M111.447 180.859C111.447 180.859 109.614 171.561 108.146 167.555C106.678 163.548 105.21 161.901 103.012 162.997C100.813 164.093 98.6076 165.551 97.1397 166.096C95.6717 166.64 93.4732 166.822 93.6558 167.555C93.8385 168.281 94.7586 170.291 96.7744 170.654C98.7902 171.017 103.377 169.928 103.377 169.928C103.377 169.928 101.726 173.027 99.7104 173.39C97.6945 173.753 88.704 173.027 88.704 173.027C88.704 173.027 93.2906 188.342 94.2037 189.431C95.1238 190.527 109.242 191.986 109.242 191.986C109.242 191.986 122.812 207.663 128.684 208.752C134.556 209.848 137.379 197.57 135.911 191.734C134.443 185.899 133.649 185.578 129.611 184.3C125.579 183.023 112.718 182.318 111.988 182.137C111.258 181.955 111.44 180.859 111.44 180.859H111.447Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M64.8582 153.88L94.0211 185.417L113.098 187.421L85.5855 153.699L64.8582 153.88Z" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M81.1885 165.726C81.1885 165.726 74.7688 167.366 74.2209 169.37C73.6731 171.373 75.8715 172.65 77.1569 172.832C78.4422 173.013 87.2431 168.092 87.2431 166.452C87.2431 164.812 83.394 164.812 81.1885 165.726Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M92.1879 172.476C92.1879 172.476 85.9507 174.116 83.1974 175.394C80.444 176.671 83.1974 179.952 85.5784 180.133C87.9595 180.315 97.1326 176.49 96.95 173.572C96.7674 170.654 94.1967 172.113 92.1808 172.476H92.1879Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M96.7744 179.763C96.7744 179.763 89.4345 179.4 87.0534 180.489C84.6724 181.585 86.8708 183.407 89.4345 183.951C92.0052 184.496 98.6076 183.951 99.5207 181.948C100.441 179.945 96.7674 179.763 96.7674 179.763H96.7744Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M96.4724 184.566C96.4724 184.566 92.0965 185.731 90.6075 186.122C89.1255 186.513 87.5592 187.756 88.0298 189.382C88.5003 191.015 89.7506 191.092 91.2326 190.778C92.7146 190.464 98.5023 187.051 99.5207 185.571C100.539 184.098 98.116 183.63 96.4724 184.559V184.566Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M216.243 216.347C218.527 218.365 224.002 238.172 225.316 252.852L223 281.697H123.706C125.357 265.049 134.97 221.896 134.97 221.896V207.922L215.042 207.81L216.243 216.347Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M253.202 192.509C253.406 195.176 253.659 205.891 252.415 220.277L240.158 280H222.901L224.313 268.343C224.727 266.493 225.76 246.969 226.174 232.995C226.589 219.02 229.483 206.072 229.483 206.072L222.901 177.648L250.842 177.257C250.842 177.257 252.998 189.843 253.202 192.509Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M139.339 221.938C139.339 221.938 136.073 222.483 157.313 223.83" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M214.22 216.256C214.22 216.256 205.777 218.692 198.156 219.502" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M192.98 215.174L193.528 221.401" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M195.979 214.902L197.342 221.673" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M169.021 215.984L168.748 224.919L160.01 224.095" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M142.106 213.855L141.467 222.517" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M145.59 214.644L144.796 221.575" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M168.565 268.594C169.021 270.437 169.548 271.973 170.152 273.076" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M165.559 231.02C165.559 231.02 165.559 252.651 167.926 265.551" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M171.578 254.648C171.578 254.648 171.74 265.516 173.952 270.242" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M152.143 101.326C152.143 101.326 129.316 103.93 128.178 107.329C127.04 110.728 130.742 135.069 130.173 141.295C129.604 147.521 128.747 168.183 130.742 174.975C132.736 181.767 131.311 187.428 131.879 191.672C132.448 195.916 129.035 208.089 131.592 210.351C134.148 212.613 137 213.178 137 213.178C137 213.178 174.022 216.291 191.111 214.309C208.2 212.326 213.897 210.909 215.035 207.796C216.172 204.683 215.891 199.308 215.891 195.623C215.891 191.937 219.312 170.431 219.312 170.431L223.583 185.431L252.408 179.98C252.408 179.98 252.633 154.865 245.792 127.697C238.951 100.53 237.251 101.661 226.996 100.244C216.741 98.8271 209.907 99.6787 209.907 99.6787L152.136 101.319L152.143 101.326Z" fill="#F9D849" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M152.143 101.326C152.902 101.982 160.193 99.5599 160.193 99.5599C160.193 99.5599 156.849 91.7071 155.332 90.1994L153.815 88.6917L152.15 101.326H152.143Z" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M158.584 99.7833C156.638 93.3964 153.934 90.9393 153.934 90.9393L153.042 90.0319C153.042 90.0319 164.308 79.2334 165.903 74.3821C167.497 69.5308 169.295 63.4859 169.295 63.4859C169.295 63.4859 177.169 96.8726 179.361 103.113C181.552 109.353 180.456 123.516 177.169 129.861C173.882 136.199 168.895 145.016 166.605 141.253C164.315 137.491 154.644 119.363 152.249 110.84C149.853 102.317 150.654 101.626 152.15 101.333C153.646 101.033 157.53 99.9439 157.53 99.9439L158.584 99.7903V99.7833Z" fill="white" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M159.869 105.514C159.525 103.329 159.076 101.424 158.577 99.7833L159.869 105.514Z" fill="white"/>
    <path d="M159.869 105.514C159.525 103.329 159.076 101.424 158.577 99.7833" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M129.885 15.343C129.885 15.343 110.232 20.4386 111.939 24.4034C113.646 28.3682 111.651 34.3084 111.651 34.3084C111.651 34.3084 100.546 60.631 107.38 73.9354C114.215 87.2398 125.895 91.1976 124.75 98.2756C123.612 105.354 116.778 119.789 124.181 130.259C131.584 140.73 145.541 142.712 145.26 145.26C144.979 147.808 142.415 149.79 142.415 149.79C142.415 149.79 143.272 152.903 150.387 147.808C157.509 142.712 155.227 129.408 152.663 123.747C150.099 118.086 157.706 97.0052 151.441 81.4392L149.818 61.1964C149.818 61.1964 157.221 54.6838 162.068 60.0656C166.907 65.4404 165.769 65.7266 170.897 90.0668C176.024 114.407 177.731 122.05 175.167 139.32C172.604 156.582 170.04 149.79 170.04 149.79C170.04 149.79 166.05 160.547 179.438 154.32C192.825 148.094 200.797 132.528 201.654 121.485C202.511 110.449 209.914 99.6926 209.914 99.6926C209.914 99.6926 205.847 83.6519 198.725 55.6331C191.603 27.6073 180.295 6.00337 163.206 6.28258C146.117 6.56877 129.885 15.343 129.885 15.343Z" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M157.46 88.7336C157.46 88.7336 165.257 81.7254 167.483 76.93C169.71 72.1345 168.6 60.1494 168.6 60.1494C168.6 60.1494 171.381 59.0395 172.871 55.1724C174.353 51.2984 172.871 48.9042 172.871 48.9042L162.848 47.4313C162.848 47.4313 162.848 49.0926 162.103 50.7539C161.358 52.4152 156.35 46.3284 156.35 46.3284L145.028 44.1157C133.706 41.9029 122.201 31.0207 122.201 31.0207C122.201 31.0207 119.419 45.0371 119.791 50.7539C120.164 56.4708 125.172 59.0535 126.289 63.109C127.405 67.1645 122.39 75.6526 124.989 76.7554C127.588 77.8653 130.742 76.0155 130.742 76.0155C130.742 76.0155 137.239 86.3463 139.276 89.4805C141.32 92.6146 143.356 95.9372 145.955 95.7488C148.554 95.5673 153.934 90.9533 153.934 90.9533" fill="white"/>
    <path d="M157.46 88.7336C157.46 88.7336 165.257 81.7254 167.483 76.93C169.71 72.1345 168.6 60.1494 168.6 60.1494C168.6 60.1494 171.381 59.0395 172.871 55.1724C174.353 51.2984 172.871 48.9042 172.871 48.9042L162.848 47.4313C162.848 47.4313 162.848 49.0926 162.103 50.7539C161.358 52.4152 156.35 46.3284 156.35 46.3284L145.028 44.1157C133.706 41.9029 122.201 31.0207 122.201 31.0207C122.201 31.0207 119.419 45.0371 119.791 50.7539C120.164 56.4708 125.172 59.0535 126.289 63.109C127.405 67.1645 122.39 75.6526 124.989 76.7554C127.588 77.8653 130.742 76.0155 130.742 76.0155C130.742 76.0155 137.239 86.3463 139.276 89.4805C141.32 92.6146 143.356 95.9372 145.955 95.7488C148.554 95.5673 153.934 90.9533 153.934 90.9533" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M133.333 55.7169C133.034 57.3703 133.128 58.1743 133.706 59.4095" stroke="#263238" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M142.057 55.9054C142.057 55.9054 135.932 60.1494 131.479 60.3309C131.479 60.3309 132.406 61.9922 133.706 61.4408C133.706 61.4408 134.822 62.7321 136.115 61.4408C137.414 60.1494 141.123 59.4095 142.057 55.9054Z" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M139.086 78.9542C139.086 78.9542 144.466 78.0328 146.32 72.316" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M127.209 54.0556C127.209 54.0556 125.404 54.9979 125.165 53.1342C124.926 51.2495 128.171 48.0665 131.844 47.9688C134.261 47.906 136.115 49.0787 136.115 49.0787C136.115 49.0787 131.107 48.8972 129.618 50.5515C128.129 52.2058 128.501 53.6856 127.209 54.0556Z" fill="#263238"/>
    <path d="M219.312 170.452C219.312 170.452 209.345 150.069 207.631 137.051" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M223.583 185.452C223.583 185.452 222.157 192.53 223.295 192.244C224.432 191.958 236.394 188.279 239.527 188.279C242.659 188.279 255.19 189.696 254.621 188.279C254.052 186.862 252.408 179.994 252.408 179.994L223.583 185.445V185.452Z" fill="#F9D849" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M163.536 202.407C162.489 206.931 162.433 210.539 163.775 211.202" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M162.848 162.78C162.77 166.906 162.967 170.87 163.775 173.279C166.05 180.07 166.619 192.244 164.632 198.47C164.498 198.896 164.365 199.315 164.245 199.727" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M164.063 147.242C164.063 147.242 163.29 153.029 162.974 159.374" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M154.201 179.554C154.201 179.554 143.651 196.816 138.693 201.339" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M144.951 202.24C144.199 203.608 143.476 204.739 142.83 205.458" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M152.129 185.515C152.129 185.515 149.221 193.563 146.278 199.643" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M64.8582 153.88L65.2796 152.254L86.625 152.149L85.5855 153.699L64.8582 153.88Z" fill="#374347" stroke="#374347" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M86.625 152.149L113.098 184.838V187.421L85.5855 153.699L86.625 152.149Z" fill="#454E51" stroke="#454E51" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M85.1492 233.349C85.3577 231.765 85.2455 230.159 84.8194 228.626C84.1647 226.137 82.9647 224.408 81.2194 223.438C79.4741 222.468 77.3422 222.323 74.8238 223.001C72.3576 223.667 70.6307 224.812 69.6431 226.438C68.6555 228.064 68.4456 229.942 69.0135 232.073C69.1464 232.577 69.325 233.068 69.5471 233.539C69.7762 234.025 70.0451 234.491 70.3512 234.933L74.8571 232.617C74.6597 232.291 74.4818 231.983 74.3252 231.7C74.1604 231.398 74.0333 231.076 73.9465 230.743C73.8374 230.405 73.8004 230.048 73.8379 229.693C73.8754 229.339 73.9866 228.995 74.1645 228.684C74.3641 228.377 74.6231 228.112 74.9263 227.905C75.2294 227.697 75.5707 227.551 75.9302 227.474C76.8542 227.225 77.6391 227.332 78.2849 227.796C78.9307 228.26 79.3963 229.025 79.6817 230.092C80.0454 231.444 80.1041 232.862 79.8536 234.246C79.591 235.69 79.1142 237.089 78.4385 238.399C77.723 239.802 76.9 241.15 75.9768 242.43L77.2586 247.264L81.9483 245.999L80.9592 242.269C81.5222 241.526 82.048 240.757 82.535 239.965M79.8794 255.031L84.3329 253.837L82.9882 248.776L77.8245 250.168L78.553 252.939" stroke="#B2B2B2" stroke-miterlimit="10"/>
    <path d="M90.8126 119.006C91.9573 117.892 92.8639 116.562 93.4794 115.094C94.5071 112.735 94.6362 110.634 93.8667 108.792C93.0973 106.949 91.5139 105.514 89.1165 104.487C86.7682 103.482 84.7029 103.312 82.9206 103.976C81.1383 104.64 79.8101 105.985 78.9361 108.009C78.728 108.488 78.5641 108.984 78.4463 109.492C78.3252 110.015 78.2476 110.547 78.2144 111.083L83.1861 112.057C83.2335 111.678 83.2841 111.327 83.3366 111.007C83.3946 110.668 83.4939 110.337 83.6323 110.021C83.756 109.688 83.9483 109.385 84.1973 109.13C84.4462 108.875 84.7463 108.674 85.0786 108.54C85.4254 108.423 85.7929 108.375 86.1595 108.4C86.5262 108.425 86.8847 108.521 87.2142 108.684C88.094 109.061 88.6438 109.631 88.8635 110.395C89.0832 111.159 88.9747 112.049 88.538 113.063C87.9858 114.35 87.1535 115.499 86.0998 116.43C84.9992 117.401 83.7578 118.205 82.4161 118.814C80.9853 119.473 79.5045 120.021 77.9868 120.454L75.9989 125.042L80.4639 126.954L81.9978 123.414C82.9 123.179 83.7891 122.901 84.6622 122.581M73.2448 132.764L77.4807 134.585L79.5598 129.779L74.644 127.673L73.4994 130.3" stroke="#B2B2B2" stroke-miterlimit="10"/>
    <circle cx="22.5" cy="110.5" r="4" stroke="#B2B2B2"/>
    <circle cx="36.5" cy="177.5" r="4" stroke="#B2B2B2"/>
    <circle cx="229.5" cy="67.5" r="4" stroke="#B2B2B2"/>
    <circle cx="208.5" cy="17.5" r="4" stroke="#B2B2B2"/>
    </svg>
    </template>